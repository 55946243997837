import Cookies from "js-cookie";
import { ImagesResponse } from "../pages/overview/models/ImageResponse";

const apiUrl = process.env.REACT_APP_API_URL;
const apiCode = process.env.REACT_APP_API_CODE;

export async function fetchGreenhouseImages(greenhouseId: string, date: string): Promise<ImagesResponse | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        const response = await fetch(`${apiUrl}/api/image?greenhouseId=${greenhouseId}&date=${date}&code=${apiCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data: ImagesResponse = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch greenhouse data:", error);
        return undefined;
    }
}