import React from "react";
import { Button, Input, Select } from "semantic-ui-react";
import { IOData } from "./models/IOData";
import { Recipe } from "../recipe/recipe";

interface ControlInputProps {
    ioItem: IOData;
    recipe: Recipe;
    defaults: Record<string, number>;
    formData: Record<string, number>;
    onChange: (ioData: IOData, newValue: number) => void;
}

const ControlInput: React.FC<ControlInputProps> = ({
    ioItem,
    recipe,
    defaults,
    formData,
    onChange,
}) => {
    const { controlName, ioDataType, description } = ioItem;

    // 1) Check if user has already set a value in formData
    let currentValue = formData[controlName];

    // 2) If not, check if recipe dictionary has a saved value
    if (currentValue === undefined && recipe.controlNames) {
        currentValue = recipe.controlNames[controlName];
    }

    // 3) If still undefined, use default
    if (currentValue === undefined) {
        if (ioItem.ioDataType === "WaterFrequency") {
            currentValue = 60 * 60 * 24;
        } else {
            currentValue = defaultsForType(ioDataType, defaults);
        }
        onChange(ioItem, currentValue);
    }

    function frequencyNumberToString(minutes: number): string {
        if (minutes === 0) return "no water";
        if (minutes === 1) return "minute";
        if (minutes === 60) return "hour";
        if (minutes === 60 * 24) return "day";
        if (minutes === 60 * 24 * 3) return "3days";
        if (minutes === 60 * 24 * 7) return "weekly";
        // fallback
        return "day";
    }

    /** Utility: Convert textual label → numeric minutes. */
    function frequencyStringToNumber(label: string): number {
        switch (label) {
            case "no water":
                return 0;
            case "minute":
                return 1;
            case "hour":
                return 60;
            case "day":
                return 60 * 24;
            case "3days":
                return 60 * 24 * 3;
            case "weekly":
                return 60 * 24 * 7;
            default:
                return 0;
        }
    }

    // Otherwise, a numeric input
    const isTemperature = ioDataType === "Temperature";
    const numericValue = currentValue ?? 0;

    // "step" helps the browser up/down arrows:
    //  - 0.5 for temperature
    //  - 1 for everything else
    const stepValue = isTemperature ? 0.5 : 1;

    if (ioDataType === "WaterFrequency") {
        // currentValue is stored as a number in seconds (e.g. 60, 3600, etc.)
        const frequencyValue = frequencyNumberToString(
            typeof currentValue === "number" ? currentValue : 60 * 24
        );

        return (
            <div style={{ display: "flex", flexDirection: "column", minWidth: "180px" }}>
                <label>
                    <b>{description}</b>
                </label>
                <Select
                    placeholder="Frequency"
                    options={[
                        { key: "no", text: "No water", value: "no water" },
                        { key: "min", text: "Minute", value: "minute" },
                        { key: "hour", text: "Hour", value: "hour" },
                        { key: "day", text: "Day", value: "day" },
                        { key: "3days", text: "Every 3 days", value: "3days" },
                        { key: "week", text: "Weekly", value: "weekly" },
                    ]}
                    value={frequencyValue}
                    onChange={(_, data) => {
                        // Convert the selected label back to numeric seconds
                        const numericValue = frequencyStringToNumber(data.value as string);
                        onChange(ioItem, numericValue);
                    }}
                />
            </div>
        );
    } else if (ioDataType === "WaterTest") {
        return (
            <div style={{ display: "flex", flexDirection: "column", minWidth: "120px" }}>
                <div>
                    <br/>
                    <Button primary style={{ width: "250px" }} onClick={(e) => onChange(ioItem, 1)}>{description}</Button>
                </div>
            </div>
        );
    }
    else {
        // For all other types, maybe just a numeric input
        return (
            <div style={{ display: "flex", flexDirection: "column", minWidth: "120px" }}>
                <label>
                    <b>{description}</b>
                </label>
                <Input
                    type="number"
                    step={stepValue}
                    min={0}
                    value={currentValue ?? 0}
                    onChange={(e) => {
                        const num = Number(e.target.value);
                        onChange(ioItem, num);
                    }}
                />
            </div>
        );
    }
};

export default ControlInput;

/** 
 * Picks a default from `defaults` or falls back to 0 if not found. 
 */
function defaultsForType(ioDataType: string, defaults: Record<string, number>): number {
    if (ioDataType in defaults) {
        return defaults[ioDataType];
    }
    return 0; // fallback if no default known for that type
}
