import React, { useState, ChangeEvent } from "react";
import { Modal, Button, Form, Checkbox } from "semantic-ui-react";
import { Greenhouse } from "./models/Greenhouse";

interface settingsProps {
    greenhouse: Greenhouse | undefined
}

const enabledFields =
    [
        "name",
        "description"
    ];

const SettingsModal: React.FC<settingsProps> = ({
    greenhouse,
}) => {

    function toTitleCase(str: string | undefined) {
        if (typeof str !== "string") return str; // Return non-strings (e.g., numbers) as is
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    }

    const disableStyle = {
        color: "grey", backgroundColor: "#f9f9f9"
    }

    const addArea = {

    }

    return (
        <div>
            <Form>
                {/* Break the Form.Group into multiple rows if needed */}
                <Form.Group>
                    <Form.Input label="Name" placeholder="name" defaultValue={greenhouse?.name} />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Input label="Description" placeholder="description" defaultValue={greenhouse?.description} />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Input label="Type" placeholder="type" defaultValue={toTitleCase(greenhouse?.type)} readOnly input={{
                        style: disableStyle
                    }} />
                    <Form.Input label="Longitude" placeholder="longitude" defaultValue={greenhouse?.longitude} readOnly input={{
                        style: disableStyle
                    }} />
                    <Form.Input label="Latitude" placeholder="latitude" defaultValue={greenhouse?.latitude} readOnly input={{
                        style: disableStyle
                    }} />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Input label="Built Date" placeholder="builtDate" defaultValue={greenhouse?.builtDate} readOnly input={{
                        style: disableStyle
                    }} />
                    <Form.Input label="Under Contract" placeholder="underContract" defaultValue={toTitleCase(greenhouse?.underContract ? "Yes" : "No")} readOnly input={{
                        style: disableStyle
                    }} />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Input label="Contract Type" placeholder="contractType" defaultValue={toTitleCase(greenhouse?.contractType)} readOnly input={{
                        style: disableStyle
                    }} />
                    <Form.Input label="Contract Start Date" placeholder="contractStartDate" defaultValue={greenhouse?.contractStartDate} readOnly input={{
                        style: disableStyle
                    }} />
                    <Form.Input label="Contract End Date" placeholder="contractEndDate" defaultValue={greenhouse?.contractEndDate} readOnly input={{
                        style: disableStyle
                    }} />
                </Form.Group>
                <br />
                <h2>Areas</h2>
                <br />
            </Form>
            {greenhouse?.areas.map(area => (
                <div>
                    <h3>{area.name}</h3>
                    <Form>
                        <Form.Group>
                            <Form.Input label="Name" placeholder="name" defaultValue={area?.name} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input label="Description" placeholder="description" defaultValue={area?.description} />
                        </Form.Group>
                    </Form>
                </div>
            ))}
            <br />
            <Button primary onClick={() => addArea}>Save</Button>
        </div>
    );
};

export default SettingsModal;