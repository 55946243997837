import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);

// ✅ Define min/max values for each data type (No TypeScript annotations)
const minMaxValues = {
    "Temperature": { minValue: -10, maxValue: 40 },
    "Humidity": { minValue: 0, maxValue: 100 },
    "Co2": { minValue: 200, maxValue: 2000 }
};

// ✅ Color palette for different datasets
const colorPalette = [
    "rgba(0,80,115, 0.9)", "rgba(16,125,172, 0.9)", "rgba(24,154,211, 0.9)",
    "rgba(30,187,215, 0.9)", "rgba(113,199,236, 0.9)", "rgba(180, 50, 50, 0.9)",
    "rgba(200, 80, 40, 0.9)", "rgba(220, 140, 50, 0.9)", "rgba(50, 180, 50, 0.9)"
];

export default function EliusLineChart({ title, labels, datasets, dataType, yValue, showLabels = true, showTitle = true }) {
    // ✅ Ensure min/max values exist
    const { minValue, maxValue } = minMaxValues[dataType] || { minValue: 0, maxValue: 100 };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
                display: showTitle
            },
            title: {
                display: showTitle,
                text: title
            }
        },
        scales: {
            x: {
                ticks: { display: showLabels },
                title: { display: true, text: "Time" }
            },
            y: {
                min: minValue,
                max: maxValue,
                title: { display: true, text: yValue }
            }
        }
    };

    // ✅ Prepare dataset structure for Chart.js (No TypeScript-specific syntax)
    const datasetConfig = datasets.map((dataset, index) => ({
        label: dataset.label,
        data: dataset.data,
        borderColor: colorPalette[index % colorPalette.length],
        backgroundColor: colorPalette[index % colorPalette.length],
        fill: false,
        tension: 0.1
    }));

    return <Line options={options} data={{ labels, datasets: datasetConfig }} />;
}
