import React, { useState, ChangeEvent, useEffect } from "react";
import { Modal, Button, Form, Table, Icon, Confirm, Radio, Checkbox } from "semantic-ui-react";
import { Greenhouse } from "./models/Greenhouse";
import { Recipe } from "../recipe/recipe";
import { createRecipe, deleteRecipe, fetchAllRecipes, updateRecipe } from "../../api/apirecipe";
import AreaForm from "./AreaForm";
import { IOData } from "./models/IOData";
import { controlTag } from "../../api/apicontrol";

interface recipeProps {
    greenhouse: Greenhouse
}

const DEFAULT_VALUES = {
    Temperature: 22.5,
    Humidity: 60,
    Co2: 1100,
    DayStart: 8,
    DayEnd: 18,
    VoC: 1,
    Water: 100,
    WaterFrequency: 3600,
    LightStart: 8,
    LightEnd: 18
};

const RecipeOverview: React.FC<recipeProps> = ({
    greenhouse,
}) => {
    const [recipies, setRecipies] = useState<Recipe[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);
    const [formData, setFormData] = useState<Recipe>({ recipeId: '', recipeName: '', recipeDescription: '', inUse: false, controlNames: {} });
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
    const [editingRecipeId, setEditingRecipeId] = useState('');
    const [activeRecipeId, setActiveRecipeId] = useState<number | null>(null);
    const [controlInputFormData, setControlInputFormData] = useState({});

    // Fetch Recipes on Mount
    useEffect(() => {
        const loadRecipes = async () => {
            setLoading(true);
            const data = await fetchAllRecipes(greenhouse?.id);
            if (data) setRecipies(data);
            setLoading(false);
        };

        loadRecipes();
    }, [greenhouse]);

    // Handle Form Changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Generic handler to update form data
    const handleValueChange = async (ioData: IOData, newValue: any) => {

        if (ioData.ioDataType === "WaterTest") {
            await controlTag(greenhouse.id, ioData.controlName, ioData.ioDataType, formData);
        } else {
            if (ioData.ioDataType === "WaterFrequency") {
                if (newValue === "nowater") {
                    newValue = 0;
                } else if (newValue === "minute") {
                    newValue = 60;
                } else if (newValue === "hour") {
                    newValue = 60 * 60;
                } else if (newValue === "day") {
                    newValue = 60 * 60 * 24;
                } else if (newValue === "3days") {
                    newValue = 60 * 60 * 24 * 3;
                } else if (newValue === "weekly") {
                    newValue = 60 * 60 * 24 * 7;
                }
            }

            setFormData((prev) => ({
                ...prev,
                controlNames: {
                    ...prev.controlNames,
                    [ioData.controlName]: newValue,
                },
            }));
        }
    };

    // Open Modal (For Adding or Editing)
    const openModal = (recipe?: Recipe) => {
        setSelectedRecipe(recipe || null);
        setFormData(recipe || { recipeId: '', recipeName: '', recipeDescription: '', inUse: false, controlNames: {} });
        setModalOpen(true);
    };

    // Handle Recipe Creation/Update
    const handleSave = async () => {

        if (editingRecipeId !== '') {
            let currentRecipe = recipies.find(obj => obj.recipeId === editingRecipeId);
            if (currentRecipe) {
                await updateRecipe(greenhouse?.id, { ...formData, recipeId: currentRecipe.recipeId });
            }
        } else {
            // Create New Recipe
            await createRecipe(greenhouse?.id, formData);
        }

        // Refresh List After Save
        const updatedRecipes = await fetchAllRecipes(greenhouse?.id);
        if (updatedRecipes) setRecipies(updatedRecipes);

        setModalOpen(false);
        setEditingRecipeId('');
    };

    // Handle Recipe Deletion
    const handleDelete = async () => {
        if (!selectedRecipe) return;
        await deleteRecipe(greenhouse?.id, selectedRecipe);

        // Refresh List After Delete
        const updatedRecipes = await fetchAllRecipes(greenhouse?.id);
        if (updatedRecipes) setRecipies(updatedRecipes);

        setModalOpen(false);
        setDeleteConfirmOpen(false);
    };

    const handleEditClick = (recipe: Recipe) => {
        setSelectedRecipe(recipe);
        setEditingRecipeId(recipe.recipeId);
        setFormData(recipe);
    };

    const handleCancel = () => {
        setEditingRecipeId('');
        setFormData({ recipeId: '', recipeName: "", recipeDescription: "", inUse: false, controlNames: {} });
    };

    // Handle Active selection
    const handleActiveCheckbox = async (recipeId: string, event: React.FormEvent, checkboxData: any) => {
        recipies.map(async recipe => {
            recipe.inUse = recipe.recipeId === recipeId && checkboxData.checked;
            await updateRecipe(greenhouse?.id, recipe);
        });

        // Optionally refetch the recipes to get the latest status from the server
        setLoading(true);
        const data = await fetchAllRecipes(greenhouse?.id);
        if (data) {
            setRecipies(data);
        }
        setLoading(false);
    };

    if (editingRecipeId) {
        // Render the Edit Form
        return (
            <div style={{ marginTop: 10 }}>
                <h2>Edit Recipe  - {formData.recipeName}</h2>
                <Form>
                    <Form.Input
                        label="Recipe Name"
                        name="recipeName"
                        value={formData.recipeName}
                        onChange={handleChange}
                        required
                    />
                    <Form.TextArea
                        label="Recipe Description"
                        name="recipeDescription"
                        value={formData.recipeDescription}
                        onChange={handleChange}
                        required
                    />
                </Form>
                {/* Loop top-level areas */}
                {greenhouse.areas.map((area) => (
                    <AreaForm
                        key={area.id}
                        area={area}
                        recipe={formData}
                        onChange={handleValueChange}
                        defaults={DEFAULT_VALUES}
                        formData={controlInputFormData}
                    />
                ))}
                <br />
                <Button onClick={handleCancel}>Cancel</Button>
                <Button primary onClick={handleSave}>Save</Button>
            </div>
        );
    }

    // Otherwise, render the recipe table
    return (
        <div style={{ marginTop: 10 }}>
            <h2>Recipe List</h2>
            <Button primary onClick={() => openModal()}>Add New Recipe</Button>
            {loading ? (
                <p>Loading recipes...</p>
            ) : (
                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>In Use</Table.HeaderCell>
                            <Table.HeaderCell>Active</Table.HeaderCell>
                            <Table.HeaderCell>Edit</Table.HeaderCell>
                            <Table.HeaderCell>Delete</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {recipies.map((recipe) => (
                            <Table.Row key={recipe.recipeId}>
                                <Table.Cell>{recipe.recipeName}</Table.Cell>
                                <Table.Cell>{recipe.recipeDescription}</Table.Cell>
                                <Table.Cell>{recipe.inUse ? "Yes" : "No"}</Table.Cell>

                                {/* Single-choice radio: user can only pick one recipe as active */}
                                <Table.Cell>
                                    <Checkbox
                                        checked={recipe.inUse}
                                        onChange={(event, data) => handleActiveCheckbox(recipe.recipeId, event, data)}
                                    />
                                </Table.Cell>

                                {/* Edit button: either open modal or navigate */}
                                <Table.Cell>
                                    <Button icon onClick={() => handleEditClick(recipe)}>
                                        <Icon name="edit" />
                                    </Button>
                                    {/* If using a modal directly, do: onClick={() => openEditModal(recipe)} */}
                                </Table.Cell>

                                {/* Delete button */}
                                <Table.Cell>
                                    <Button
                                        color="red"
                                        icon
                                        onClick={() => {
                                            setSelectedRecipe(recipe);
                                            setDeleteConfirmOpen(true);
                                        }}
                                    >
                                        <Icon name="trash" />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}

            {/* Recipe Modal */}
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Header>{selectedRecipe ? "Edit Recipe" : "Add New Recipe"}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input
                            label="Recipe Name"
                            name="recipeName"
                            value={formData.recipeName}
                            onChange={handleChange}
                            required
                        />
                        <Form.TextArea
                            label="Recipe Description"
                            name="recipeDescription"
                            value={formData.recipeDescription}
                            onChange={handleChange}
                            required
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    {selectedRecipe && (
                        <Button color="red" onClick={() => setDeleteConfirmOpen(true)}>
                            <Icon name="trash" /> Delete
                        </Button>
                    )}
                    <Button onClick={() => setModalOpen(false)}>Cancel</Button>
                    <Button primary onClick={handleSave}>Save</Button>
                </Modal.Actions>
            </Modal>
            {/* Confirmation for delete (from your snippet) */}
            {/* Only opens when deleteConfirmOpen = true, which we set above */}
            {/* (You already have handleDelete logic, so we reuse it) */}
            <Confirm
                open={deleteConfirmOpen}
                onCancel={() => setDeleteConfirmOpen(false)}
                onConfirm={handleDelete}
                content="Are you sure you want to delete this recipe?"
                confirmButton="Delete"
                cancelButton="Cancel"
                size="mini"
            />
        </div>
    );
};

export default RecipeOverview;