import Cookies from "js-cookie";
import { ImagesResponse } from "../pages/overview/models/ImageResponse";
import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";
import { LoginResponse } from "./LoginResponse";
import { UpdatePasswordResponse } from "./UpdatePasswordResponse";
import { Greenhouse } from "../pages/overview/models/Greenhouse";
import { TimeseriesData } from "../pages/overview/models/TimeseriesData";

const apiUrl = process.env.REACT_APP_API_URL;
const apiCode = process.env.REACT_APP_API_CODE;

export async function fetchTimeseriesData(greenhouseId: string, start: string, end: string): Promise<any[] | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        const response = await fetch(`${apiUrl}/api/timeseries?greenhouseId=${greenhouseId}&start=${start}&end=${end}&code=${apiCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data: any[] = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch timeseries data:", error);
        return undefined;
    }
}