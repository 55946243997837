import React, { useState, useEffect, useMemo } from 'react'
import { Statistic, Search, Grid, Table, Button } from 'semantic-ui-react'
import { AzureMap, AzureMapsProvider, IAzureMapOptions, AuthenticationType, IAzureMapHtmlMarker, AzureMapFeature, IAzureMapFeature, AzureMapDataSourceProvider, IAzureMapLayerType, AzureMapHtmlMarker, IAzureMapHtmlMarkerEvent, IAzureDataSourceChildren, AzureSetCameraOptions } from 'react-azure-maps'
import '../../styles/OverviewStyles.css';
import atlas, { HtmlMarkerOptions, CameraOptions, data } from 'azure-maps-control';
import { NavigateOptions, useNavigate } from "react-router-dom";
import { fetchGreenhouseOverview } from '../../api/apicontainer';
import { Greenhouse } from './models/Greenhouse';

const mapOption: IAzureMapOptions = {
    authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: 'yFVQCxrNK0tOMgXPsCoiMC738WaSh3Y4dswDnO7aKrA' // Your subscription key
    }
}

function azureHtmlMapMarkerOptions(id: string, longitude: number, latitude: number): HtmlMarkerOptions {
    let coordinates = [latitude, longitude];
    return {
        position: coordinates,
        text: id,
        title: 'Title'
    };
}

export default function Overview() {
    const [dataLoading, setDataLoading] = useState(false);
    const [overviewData, setOverviewData] = useState<Greenhouse[]>();
    const [cameraOptions, setCameraOptions] = useState<AzureSetCameraOptions>();
    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            setDataLoading(true);
            try {
                const response = await fetchGreenhouseOverview();
                setOverviewData(response);

            } catch (error) {
                console.error("Failed to load data:", error);
            } finally {
                setDataLoading(false);
            }
        };

        // Call the async function
        loadData();
    }, [])

    useEffect(() => {
        if (overviewData !== undefined) {

            let longitude = overviewData.map(greenhouse => greenhouse.longitude).reduce((x, y) => x + y) / overviewData?.length;
            let latitude = overviewData.map(greenhouse => greenhouse.latitude).reduce((x, y) => x + y) / overviewData?.length;
            let centerPosition = new data.Position(latitude, longitude);

            const options = {} as CameraOptions;
            options.center = centerPosition;
            options.zoom = 7;
            setCameraOptions(options);
        }
    }, [overviewData])

    const renderHTMLPoint = (greenhouse: Greenhouse): any => {
        return (
            <AzureMapHtmlMarker
                key={greenhouse.id}
                markerContent={greenhouse.isOnline ? <div className={"pulseIcon"}></div> : <div className={"pulseIconError"}></div>}
                options={{ ...azureHtmlMapMarkerOptions(greenhouse.id, greenhouse.longitude, greenhouse.latitude) } as any}
                events={eventToMarker}
            />
        );
    }

    const containerMapMarkerClick = (e: any) => {
        let greenhouseId = e.target.options.text;

        let options = {} as NavigateOptions;
        options.state = { greenhouseId: greenhouseId };
        navigate(`/overview/${greenhouseId}`, options);
    };

    const containerListClick = (greenhouseId: string) => {
        let options = {} as NavigateOptions;
        options.state = { greenhouseId: greenhouseId };
        navigate(`/overview/${greenhouseId}`, options);
    };

    const eventToMarker: Array<IAzureMapHtmlMarkerEvent> = [{ eventName: 'click', callback: containerMapMarkerClick }];

    const memoizedHtmlMarkerRender: IAzureDataSourceChildren = useMemo(
        (): any => overviewData?.map((greenhouse) => renderHTMLPoint(greenhouse)),
        [overviewData],
    );

    const containerAddClick = () => {
        // let options = {} as NavigateOptions;
        // options.state = { greenhouseId: greenhouseId };
        // navigate(`/overview/${greenhouseId}`, options);
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={6}>
                    <div>
                        <br/>
                        <Button primary>Add Greenhouse</Button>
                        <Table color='blue' key='blue' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Greenhouse</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                    <Table.HeaderCell>Latest connection</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    overviewData?.map(greenhouse => (
                                        <Table.Row className='TableRowClickable'
                                            onClick={() => {
                                                containerListClick(greenhouse.id);
                                            }}>
                                            <Table.Cell>{greenhouse.name}</Table.Cell>
                                            <Table.Cell className={greenhouse.isOnline ? 'ContainerPerformanceGood' : 'ContainerPerformanceBad'}>{greenhouse.isOnline ? "Online" : "Offline"}</Table.Cell>
                                            <Table.Cell>
                                                {greenhouse.lastConnectionTime
                                                    ? new Date(greenhouse.lastConnectionTime).toLocaleString('en-GB', {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: false, // Ensures 24-hour format
                                                    }).replace(/^(\d{2})\/(\d{2})\/(\d{4})/, '$3/$2/$1') // Reorder to yyyy/MM/dd
                                                    : 'N/A'}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </Grid.Column>
                <Grid.Column width={9}>
                    <AzureMapsProvider>
                        <div style={{ height: '600px', width: '100%' }}>
                            <AzureMap options={mapOption} cameraOptions={cameraOptions}>
                                <AzureMapDataSourceProvider id="MultiplePoint AzureMapDataSourceProvider">
                                    {memoizedHtmlMarkerRender}
                                </AzureMapDataSourceProvider>
                            </AzureMap>
                        </div>
                    </AzureMapsProvider>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}