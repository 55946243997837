import Cookies from "js-cookie";
import { Device } from "../models/device/device";

const apiUrl = process.env.REACT_APP_API_URL;
const apiCode = process.env.REACT_APP_API_CODE;

export async function fetchAllDevices(greenhouseId: string): Promise<Device[] | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        const response = await fetch(`${apiUrl}/api/device?greenhouseId=${greenhouseId}&code=${apiCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data: Device[] = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch greenhouse data:", error);
        return undefined;
    }
}

export async function fetchDevice(greenhouseId: string, deviceId: string): Promise<Device | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        const response = await fetch(`${apiUrl}/api/device?greenhouseId=${greenhouseId}&deviceId=${deviceId}&code=${apiCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data: Device = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch greenhouse data:", error);
        return undefined;
    }
}

export async function updateDevice(greenhouseId: string, device: Device): Promise<boolean | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        // Make the API call
        const response = await fetch(`${apiUrl}/api/device?greenhouseId=${greenhouseId}&code=${apiCode}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(device),
        });


        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        return true;
    } catch (error) {
        console.error("Failed to fetch greenhouse data:", error);
        return undefined;
    }
}

export async function createDevice(greenhouseId: string, device: Device): Promise<boolean | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        // Make the API call
        const response = await fetch(`${apiUrl}/api/device?greenhouseId=${greenhouseId}&code=${apiCode}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(device),
        });


        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        return true;
    } catch (error) {
        console.error("Failed to fetch greenhouse data:", error);
        return undefined;
    }
}

export async function deleteDevice(greenhouseId: string, device: Device): Promise<boolean | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        // Make the API call
        const response = await fetch(`${apiUrl}/api/device?greenhouseId=${greenhouseId}&code=${apiCode}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(device),
        });


        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        return true;
    } catch (error) {
        console.error("Failed to fetch greenhouse data:", error);
        return undefined;
    }
}