import React from "react";
import { Greenhouse } from "./models/Greenhouse";
import { Recipe } from "../recipe/recipe";
import ControlInput from "./ControlInput";
import { IOData } from "./models/IOData";
import { GreenhouseArea } from "./models/GreenhouseArea";

// A small groupBy helper
function groupBy<T>(array: T[], getKey: (item: T) => string | number) {
  return array.reduce<Record<string, T[]>>((acc, item) => {
    const key = String(getKey(item));
    if (!acc[key]) acc[key] = [];
    acc[key].push(item);
    return acc;
  }, {});
}

interface AreaFormProps {
  area: GreenhouseArea;
  recipe: Recipe;
  defaults: Record<string, number>;
  formData: Record<string, number>;
  onChange: (ioData: IOData, newValue: number) => void;
}

const AreaForm: React.FC<AreaFormProps> = ({
  area,
  recipe,
  defaults,
  formData,
  onChange,
}) => {
  // Filter out non-controllable I/O
  const controllableIo = [...area.ioData]
  .filter((io) => io.controllable)
  .sort((a, b) => a.id - b.id);
  
  // Group by controlGroupId (default to 0 if not set)
  const grouped = groupBy(controllableIo, (io: IOData) => io.controlGroupId || 0);

  return (
    <div style={{ border: "1px solid #ccc", margin: "1rem 0", padding: "1rem" }}>
      <h3>{area.name}</h3>
      <p>{area.description}</p>

      {/* For each groupId, render the items side-by-side if groupId>0 */}
      {Object.entries(grouped).map(([groupId, items]) => {
        const isGrouped = parseInt(groupId, 10) > 0;
        return (
          <div
            key={groupId}
            style={{
              display: isGrouped ? "flex" : "block",
              gap: "1rem",
              marginBottom: "1rem",
            }}
          >
            {items.filter(obj => obj.ioDataType !== "OnOff" && obj.ioDataType !== "AC_Mode").map((ioItem) => (
              <ControlInput
                key={ioItem.id}
                ioItem={ioItem}
                recipe={recipe}
                defaults={defaults}
                formData={formData}
                onChange={onChange}
              />
            ))}
          </div>
        );
      })}

      {/* Render sub-areas (recursive) */}
      {area.areas && area.areas.length > 0 && (
        <div style={{ marginLeft: "1rem", borderLeft: "4px solid #ddd", paddingLeft: "1rem" }}>
          {area.areas.map((subArea: GreenhouseArea) => (
            <AreaForm
              key={subArea.id}
              area={subArea}
              recipe={recipe}
              defaults={defaults}
              formData={formData}
              onChange={onChange}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AreaForm;
