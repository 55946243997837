import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Tab, Grid } from "semantic-ui-react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { fetchGreenhouseData } from '../../api/apicontainer';
import { Greenhouse } from './models/Greenhouse';
import SettingsModal from './SettingsModal';
import RecipeOverview from './RecipeOverview';
import ContainerOverviewData from './containerOverviewData';
import { fetchTimeseriesData } from '../../api/apitimeseries';
import DeviceOverview from './DeviceOverview';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function ContainerOverview() {

    const location = useLocation();
    const [greenhouse, setGreenhouse] = useState<Greenhouse | undefined>(undefined);
    const [timeseries, setTimeseries] = useState<any[] | undefined>(undefined);

    // Displaying AI-images
    const greenhouseId = location.state?.greenhouseId as string;

    const panes = [
        {
            menuItem: "Data",
            render: () => (
                <Tab.Pane>
                    {greenhouse &&
                        <ContainerOverviewData greenhouse={greenhouse} timeseries={timeseries} />
                    }
                </Tab.Pane>
            )
        },
        {
            menuItem: "Configuration",
            render: () => (
                <Tab.Pane>
                    <SettingsModal greenhouse={greenhouse} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Recipe",
            render: () => (
                <Tab.Pane>
                    <div>
                        {greenhouse &&
                            <RecipeOverview greenhouse={greenhouse} />
                        }
                    </div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Devices",
            render: () => (
                <Tab.Pane>
                    <div>
                        {greenhouse &&
                            <DeviceOverview greenhouse={greenhouse} />
                        }
                    </div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Alarms",
            render: () => (
                <Tab.Pane>
                    <div>

                    </div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Control System",
            render: () => (
                <Tab.Pane>
                    <div>

                    </div>
                </Tab.Pane>
            ),
        }
    ];

    useEffect(() => {

        const loadData = async () => {
            try {
                // Load configuration
                const response = await fetchGreenhouseData(greenhouseId);
                setGreenhouse(response);

                let dataSetLabels = [];

                // Load time series
                let times = getFormattedDateTime();
                const timeseriesResponse = await fetchTimeseriesData(greenhouseId, times.start, times.end);
                setTimeseries(timeseriesResponse);

            } catch (error) {
                console.error("Failed to load data:", error);
            } finally {
            }
        };

        loadData();
    }, [])

    const getFormattedDateTime = (subtractHours = 12): { start: string, end: string } => {
        const now = new Date();
        const startDate = new Date(now.getTime() - subtractHours * 60 * 60 * 1000); // Subtract 12 hours

        const format = (date: Date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits for month
            const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day
            const hours = String(date.getHours()).padStart(2, '0'); // Ensure two digits for hours
            return { date: `${year}${month}${day}`, time: `${hours}:00` };
        };

        const start = format(startDate);
        const end = format(now);

        return { start: `${start.date}T${start.time}`, end: `${end.date}T${end.time}` };
    };

    return (
        <Grid style={{ height: "100vh", padding: "0" }}>
            <Grid.Row style={{ padding: 0, marginTop: 15, height: "100%" }}>
                {/* Tabs Section */}
                <Tab
                    panes={panes}
                    menu={{ attached: "top", tabular: true }}
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                />
            </Grid.Row>
        </Grid>
    );
}