import { Greenhouse } from "../pages/overview/models/Greenhouse";
import { GreenhouseArea } from "../pages/overview/models/GreenhouseArea";


/**
 * Finds a controlId based on deviceId and controlType from the Greenhouse object.
 * @param greenhouse - The Greenhouse object to search in.
 * @param deviceId - The specific deviceId to match.
 * @param controlType - The controlType (ioDataType) to match.
 * @returns The matching controlId or null if not found.
 */
export function findControlId(greenhouse: Greenhouse, deviceId: string, controlType: string): string | undefined {

    // Recursive helper function to search areas
    const searchAreas = (areas: GreenhouseArea[]): string | undefined => {
        for (const area of areas) {

            // Check ioData in the current area
            for (const ioData of area.ioData) {
                if (ioData.deviceId === deviceId && ioData.ioDataType === controlType) {
                    return ioData.controlName;
                }
            }

            // Recursively search nested areas
            const foundInSubArea = searchAreas(area.areas);
            if (foundInSubArea !== undefined) {
                return foundInSubArea;
            }
        }

        return undefined; // Not found
    };

    return searchAreas(greenhouse.areas);
};
