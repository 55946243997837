import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Card, Grid, Statistic, Image, Modal, Button, Loader, Dimmer } from "semantic-ui-react";
import EliusLineChart from '../../components/charts/eliuslinechart';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataset,
} from 'chart.js';
import { Greenhouse } from './models/Greenhouse';
import { fetchGreenhouseImages } from '../../api/apiimages';
import { ImageData } from '../overview/models/ImageResponse';
import { fetchAllDevices, fetchDevice } from '../../api/apidevice';
import { Device } from '../../models/device/device';
import AirConditionersDisplay, { AirConditionerProps } from '../../components/AirConditioner';
import AirConditioner from '../../components/AirConditioner';
import { IOData } from './models/IOData';
import { controlTag } from '../../api/apicontrol';
import { findControlId } from '../../helpers/GreenhouseHelper';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface overviewData {
    greenhouse: Greenhouse
    timeseries: any[] | undefined
}

interface cardData {
    id: number;
    name: string;
    value: number;
    type: string;
    target: number;
    timeseriesData: number[];
    timeseriesTime: number[];
}

const roundTo30MinutesLocal = (utcDateString: string) => {
    const utcDate = new Date(utcDateString); // Convert timestamp string to Date object (UTC)
    const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000); // Convert to local time

    const minutes = localDate.getMinutes();
    const roundedMinutes = minutes < 30 ? 0 : 30;
    localDate.setMinutes(roundedMinutes, 0, 0);

    return localDate.toISOString(); // Store full ISO timestamp for sorting
};

const ContainerOverviewData: React.FC<overviewData> = ({ greenhouse, timeseries }) => {
    const [allCardData, setCardData] = useState<cardData[]>([]);
    const [selectedData, setSelectedData] = useState<cardData | null>(null);
    const [images, setImages] = useState<ImageData[] | undefined>(undefined);
    const [devices, setDevices] = useState<Device[] | undefined>(undefined);
    const [isImageModalOpen, setImageIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);
    const [ioData, setIOData] = useState<IOData[] | null>(null);
    const [airConditioners, setAirConditioners] = useState<AirConditionerProps[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const [temperatureData, setTemperatureData] = useState<ChartDataset[]>([]);
    const [humidityData, setHumidityData] = useState<ChartDataset[]>([]);
    const [co2Data, setCo2Data] = useState<ChartDataset[]>([]);
    const [imageDate, setImageDate] = useState<string>("")
    const [loadingImages, setLoadingImages] = useState<boolean>(false);

    // Function to toggle AC state
    const toggleAC = async (id: string, value: any, controlType: string) => {

        const controlName = findControlId(greenhouse, id, controlType);

        if (controlName !== undefined) {
            console.log(greenhouse.id, controlName, controlType, { value: value });

            if (controlType === "AC_Mode") {
                await controlTag(greenhouse.id, controlName, controlType, { value: value });
                setAirConditioners((prevACs) =>
                    prevACs.map((ac) =>
                        ac.id === id ? { ...ac, mode: value } : ac
                    )
                );
            } else if (controlType === "OnOff") {
                await controlTag(greenhouse.id, controlName, controlType, { value: value === false ? 0 : 1 });
                setAirConditioners((prevACs) =>
                    prevACs.map((ac) =>
                        ac.id === id ? { ...ac, isOn: value } : ac
                    )
                );
            }
        }
    };

    // Helper function to find timeseries data for a specific controlName
    const getTimeseriesData = (controlName: string) => {
        const timeseriesData: number[] = [];
        const timeseriesTime: number[] = [];

        timeseries?.forEach((entry) => {
            if (entry.hasOwnProperty(controlName)) {
                timeseriesData.push(entry[controlName]);
                timeseriesTime.push(new Date(entry.timestamp).getTime());
            }
        });

        return { timeseriesData, timeseriesTime };
    };

    useEffect(() => {
        const makeIODataList = () => {
            let iodataList: IOData[] = [];

            greenhouse?.areas?.forEach((area) => {
                area.ioData?.filter(io => io.ioType === "Output").forEach((io) => {
                    iodataList.push(io);
                });
            });

            setIOData(iodataList);
        };
        makeIODataList();
    }, [greenhouse]);

    useEffect(() => {
        if (!greenhouse || !timeseries) return; // Prevent running if props are undefined

        const getCardData = () => {
            const data: cardData[] = [];

            greenhouse?.areas?.forEach((area) => {
                area.ioData?.filter(io => io.ioType === "Output").forEach((io) => {
                    const { timeseriesData, timeseriesTime } = getTimeseriesData(io.controlName);
                    const latestValue = timeseriesData[timeseriesData.length - 1] || 0;

                    const card: cardData = {
                        id: io.id,
                        name: io.description,
                        value: truncateToDecimal(latestValue, 1),
                        type: io.ioDataType,
                        target: 0, // Default target
                        timeseriesData,
                        timeseriesTime,
                    };

                    data.push(card);
                });
            });

            setCardData(data);
            if (data.length > 0) setSelectedData(data[0]); // Default to first card
        };

        getCardData();
    }, [greenhouse, timeseries]); // Re-run when `greenhouse` or `timeseries` changes

    // Fetch devices after greenhouse data is available
    useEffect(() => {
        if (!greenhouse?.id) return;

        const loadDevices = async () => {
            const response = await fetchAllDevices(greenhouse.id);
            if (response) {
                let airConditionerDevices: AirConditionerProps[] = [];

                response.filter(obj => obj.deviceType === "AirConditioner").map(ac => {

                    if (ioData !== null) {
                        // Get IOData related to AC
                        let iodataList = ioData?.filter(obj => obj.deviceId === ac.id);

                        if (iodataList !== undefined && iodataList?.length > 0) {

                            // Get latest time series for IOData
                            if (timeseries !== undefined && timeseries?.length > 0) {
                                let latest = timeseries![timeseries!.length - 1];

                                //Create new AC object
                                airConditionerDevices.push({
                                    id: ac.id,
                                    isOn: latest["aconoff"],
                                    mode: latest["acmode"],
                                    name: ac.name,
                                    outsideTemperature: 22.4,
                                    temperatureMeasured: latest["acMeasuredTemperature"],
                                    temperatureSetPoint: latest["acTemperatureSet"]
                                });
                            }
                        }
                    }
                });

                setDevices(response);
                setAirConditioners(airConditionerDevices);
            }
        };

        loadDevices();
    }, [greenhouse?.id, ioData, timeseries]); // Runs when `greenhouse.id` changes

    useEffect(() => {
        if (!greenhouse?.id || !timeseries || !ioData) return;

        const prepareChartData = () => {
            const groupedData: Record<string, { [sensor: string]: number[] }> = {};
            const temperatureSeries: Record<string, { [interval: string]: number[] }> = {};
            const humiditySeries: Record<string, { [interval: string]: number[] }> = {};
            const co2Series: Record<string, { [interval: string]: number[] }> = {};

            // **Step 1: Find All Sensors**
            ioData.forEach((device) => {
                if (device.ioDataType === "Temperature") temperatureSeries[device.controlName] = {};
                if (device.ioDataType === "Humidity") humiditySeries[device.controlName] = {};
                if (device.ioDataType === "Co2") co2Series[device.controlName] = {};
            });

            // **Step 2: Process & Group Data into 30-Minute Intervals**
            timeseries.forEach((entry) => {
                if (!entry.timestamp) return;

                const interval = roundTo30MinutesLocal(entry.timestamp); // ✅ Convert UTC to Local and round

                if (!groupedData[interval]) groupedData[interval] = {};

                // **Loop Through Sensors and Assign Values**
                Object.keys(temperatureSeries).forEach((key) => {
                    if (!(key in groupedData[interval])) groupedData[interval][key] = [];
                    if (typeof entry[key] === "number") groupedData[interval][key].push(entry[key] as number);
                });

                Object.keys(humiditySeries).forEach((key) => {
                    if (!(key in groupedData[interval])) groupedData[interval][key] = [];
                    if (typeof entry[key] === "number") groupedData[interval][key].push(entry[key] as number);
                });

                Object.keys(co2Series).forEach((key) => {
                    if (!(key in groupedData[interval])) groupedData[interval][key] = [];
                    if (typeof entry[key] === "number") groupedData[interval][key].push(entry[key] as number);
                });
            });

            // **Step 3: Compute Averages**
            const sortedLabels = Object.keys(groupedData)
                .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
                .map((timestamp) => new Date(timestamp).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" })); // ✅ Show Local Time

            const finalTemperature: Record<string, number[]> = {};
            const finalHumidity: Record<string, number[]> = {};
            const finalCo2: Record<string, number[]> = {};

            Object.entries(groupedData).forEach(([interval, values]) => {
                Object.keys(values).forEach((sensor) => {
                    const avgValue = values[sensor].reduce((sum, v) => sum + v, 0) / values[sensor].length;

                    let newKey = ioData.find(obj => obj.controlName === sensor)?.description;
                    newKey = newKey === undefined ? sensor : newKey;

                    if (sensor in temperatureSeries) {
                        if (!finalTemperature[newKey]) finalTemperature[newKey] = [];
                        finalTemperature[newKey].push(avgValue);
                    }

                    if (sensor in humiditySeries) {
                        if (!finalHumidity[newKey]) finalHumidity[newKey] = [];
                        finalHumidity[newKey].push(avgValue);
                    }

                    if (sensor in co2Series) {
                        if (!finalCo2[newKey]) finalCo2[newKey] = [];
                        finalCo2[newKey].push(avgValue);
                    }
                });
            });

            // **Step 4: Convert Data to Chart.js Format**
            const generateDataset = (series: Record<string, number[]>, type: string): ChartDataset[] =>
                Object.entries(series).map(([key, values], index) => ({
                    label: key,
                    data: values,
                    borderColor: `hsl(${index * 50}, 70%, 50%)`,
                    backgroundColor: `hsl(${index * 50}, 70%, 70%)`,
                }));

            setLabels(sortedLabels); // ✅ Labels now show local `HH:mm`
            setTemperatureData(generateDataset(finalTemperature, "Temperature"));
            setHumidityData(generateDataset(finalHumidity, "Humidity"));
            setCo2Data(generateDataset(finalCo2, "CO₂"));
        };

        prepareChartData();
    }, [greenhouse?.id, ioData, timeseries]);

    // Fetch images after greenhouse data is available
    useEffect(() => {
        if (!greenhouse?.id) return;

        const loadImages = async () => {
            const currentDate = new Date();
            const newYear = currentDate.getFullYear();
            const newMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // add 1 to month
            const newDay = String(currentDate.getDate()).padStart(2, '0');
            const newDate = `${newYear}${newMonth}${newDay}`;

            const response = await fetchGreenhouseImages(greenhouse.id, newDate);
            if (response) {
                setImages(response.images);
                setImageDate(newDate);
            }
        };

        loadImages();
    }, [greenhouse?.id]); // Runs when `greenhouse.id` changes

    function truncateToDecimal(value: number, decimals: number) {
        if (Number.isInteger(value)) return value;
        const factor = Math.pow(10, decimals);
        return Math.floor(value * factor) / factor;
    }

    const getSortedImages = (): ImageData[] => {
        if (!images || images.length === 0) return []; // Prevent errors if images are undefined

        return [...images]
            .filter((img) => img.timestamp) // Ensure timestamp exists
            .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    };
    const sortedImages = useMemo(() => getSortedImages(), [images]);

    const openImageModal = (image: ImageData) => {
        setSelectedImage(image);
        setImageIsModalOpen(true);
    };

    const closeImageModal = () => {
        setImageIsModalOpen(false);
        setSelectedImage(null);
    };

    const changeDate = async (direction: 'forward' | 'backward') => {
        setLoadingImages(true); // Start loading

        const year = parseInt(imageDate.substring(0, 4));
        const month = parseInt(imageDate.substring(4, 6)) - 1; // JS months are 0-indexed
        const day = parseInt(imageDate.substring(6, 8));

        const currentDate = new Date(year, month, day);
        currentDate.setDate(currentDate.getDate() + (direction === 'forward' ? 1 : -1));

        // Extract local date parts to avoid timezone issues
        const newYear = currentDate.getFullYear();
        const newMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // add 1 to month
        const newDay = String(currentDate.getDate()).padStart(2, '0');

        const newDate = `${newYear}${newMonth}${newDay}`;
        setImageDate(newDate);

        const response = await fetchGreenhouseImages(greenhouse.id, newDate);

        if (response) {
            setImages(response.images);
            setLoadingImages(false); // Stop loading
        }
    };

    return (
        <Grid padded>
            <Grid.Row columns={2}>
                {/* <Grid.Column width={4}>
                    {allCardData.map((data) => (
                        <Grid.Column
                            key={data.id}
                            computer={2} tablet={8} mobile={16}
                            className="grid-column-compact"
                            onClick={() => setSelectedData(data)}
                        >
                            <div
                                className="NumberCard"
                                style={{
                                    minWidth: "250px",
                                    padding: "1rem",
                                    textAlign: "center",
                                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "10px",
                                    background: "white",
                                    marginBottom: "10px"
                                }}
                            >
                                <Statistic
                                    size="small"
                                    color={data.target === 0 ? 'grey' : data.value * 1.05 > data.target ? 'red' : 'green'}
                                    className="SemanticStatisticCard"
                                >
                                    <Statistic.Label>{data.name}</Statistic.Label>
                                    <Statistic.Value>{data.value}</Statistic.Value>
                                    <Statistic.Label>{"Target: " + data.target}</Statistic.Label>
                                </Statistic>
                            </div>
                        </Grid.Column>
                    ))}
                </Grid.Column> */}
                <Grid.Column width={4} style={{ flexDirection: "column", alignItems: "flex-end" }}>
                    {airConditioners && airConditioners.map(airConditioner => (
                        <AirConditioner
                            key={airConditioner.id}
                            ac={airConditioner}
                            toggleAC={(acid: string, value: any, controlType: string) => toggleAC(acid, value, controlType)}
                        />
                    ))}
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={16}>
                    <div className="EliusChart">
                        <EliusLineChart title="Temperature" labels={labels} datasets={temperatureData} dataType="Temperature" yValue="Temperature (°C)" />
                    </div>
                    <div className="EliusChart">
                        <EliusLineChart title="Humidity" labels={labels} datasets={humidityData} dataType="Humidity" yValue="Humidity (%)" />
                    </div>
                    <div className="EliusChart">
                        <EliusLineChart title="CO₂ Levels" labels={labels} datasets={co2Data} dataType="Co2" yValue="CO₂ (ppm)" />
                    </div>
                </Grid.Column>
            </Grid.Row>

            {/* Display Images */}
            {sortedImages.length > 0 && (
                <Grid.Row>
                    <Grid.Column width={16} style={{ textAlign: 'center' }}>
                        <h3>Images</h3>
                        <Grid.Row>
                            <Grid.Column width={16} style={{ textAlign: 'center', marginBottom: '1rem' }}>
                                <Button onClick={() => changeDate('backward')} disabled={loadingImages}>
                                    &lt;
                                </Button>
                                <span style={{ margin: '0 1rem', fontWeight: 'bold' }}>
                                    {`${imageDate.substring(0, 4)}-${imageDate.substring(4, 6)}-${imageDate.substring(6, 8)}`}
                                </span>
                                <Button onClick={() => changeDate('forward')} disabled={loadingImages}>
                                    &gt;
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Dimmer active={loadingImages} inverted>
                                <Loader>Loading...</Loader>
                            </Dimmer>
                            <Card.Group itemsPerRow={4} stackable>
                                {sortedImages.map((img, index) => (
                                    <Card key={index} onClick={() => openImageModal(img)} style={{ cursor: 'pointer' }}>
                                        <Card.Content>
                                            <Card.Header>
                                                {new Date(img.timestamp).toLocaleString()}
                                            </Card.Header>
                                        </Card.Content>
                                        <Image
                                            src={`data:image/png;base64,${img.base64Image}`}
                                            alt={`Greenhouse ${index}`}
                                            style={{ maxHeight: '200px', objectFit: 'cover' }}
                                        />
                                        <Card.Description>
                                            {devices?.find(obj => obj.macId === img.macId)?.name || img.macId}
                                        </Card.Description>
                                    </Card>
                                ))}
                            </Card.Group>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            )}

            <Modal open={isImageModalOpen} onClose={closeImageModal} size="large">
                <Modal.Header>
                    {selectedImage ? new Date(selectedImage.timestamp).toLocaleString() : ""}
                </Modal.Header>
                <Modal.Content image>
                    {selectedImage && (
                        <Image
                            src={`data:image/png;base64,${selectedImage.base64Image}`}
                            wrapped
                            style={{ width: '100%', maxHeight: '90vh', objectFit: 'contain' }}
                        />
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeImageModal} primary>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        </Grid>
    );
}

export default ContainerOverviewData;