import Cookies from "js-cookie";
import { Greenhouse } from "../pages/overview/models/Greenhouse";

const apiUrl = process.env.REACT_APP_API_URL;
const apiCode = process.env.REACT_APP_API_CODE;

export async function fetchGreenhouseData(greenhouseId: string): Promise<Greenhouse | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        const response = await fetch(`${apiUrl}/api/greenhouse?greenhouseId=${greenhouseId}&code=${apiCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data: Greenhouse = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch greenhouse data:", error);
        return undefined;
    }
}

export async function fetchGreenhouseOverview(): Promise<Greenhouse[] | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        const response = await fetch(`${apiUrl}/api/greenhouse?code=${apiCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data: Greenhouse[] = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch greenhouse overview data:", error);
        return undefined;
    }
}