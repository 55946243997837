import React, { useState, ChangeEvent, useEffect } from "react";
import { Modal, Button, Form, Table, Icon, Confirm, Radio, Checkbox, DropdownProps } from "semantic-ui-react";
import { Greenhouse } from "./models/Greenhouse";
import { createDevice, deleteDevice, fetchAllDevices, updateDevice } from "../../api/apidevice";
import { IOData } from "./models/IOData";
import { Device } from "../../models/device/device";

interface deviceProps {
    greenhouse: Greenhouse
}

const DeviceOverview: React.FC<deviceProps> = ({
    greenhouse,
}) => {
    const [devices, setRecipies] = useState<Device[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
    const [formData, setFormData] = useState<Device>({ id: '', name: '', macId: '', deviceType: '' });
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
    const [editingDeviceId, setEditingDeviceId] = useState('');
    const [activeDeviceId, setActiveDeviceId] = useState<number | null>(null);
    const [controlInputFormData, setControlInputFormData] = useState({});

    const deviceOptions = [
        { key: 'camera', value: 'Camera', text: 'Camera' },
        { key: 'climatedevice', value: 'ClimateDevice', text: 'ClimateDevice' },
        { key: 'levelsensor', value: 'LevelSensor', text: 'LevelSensor' },
        { key: 'fan', value: 'Fan', text: 'Fan' },
        { key: 'airconditioner', value: 'AirConditioner', text: 'AirConditioner' },
        { key: 'motionsensor', value: 'MotionSensor', text: 'MotionSensor' }
    ]

    // Fetch Devices on Mount
    useEffect(() => {
        const loadDevices = async () => {
            setLoading(true);
            const data = await fetchAllDevices(greenhouse?.id);
            if (data) setRecipies(data);
            setLoading(false);
        };

        loadDevices();
    }, [greenhouse]);

    // Open Modal (For Adding or Editing)
    const openModal = (device?: Device) => {
        setSelectedDevice(device || null);
        setFormData(device || { id: '', name: '', macId: '', deviceType: '' });
        setModalOpen(true);
    };

    // Handle Device Creation/Update
    const handleSave = async () => {

        if (editingDeviceId !== '') {
            let currentDevice = devices.find(obj => obj.id === editingDeviceId);
            if (currentDevice) {
                await updateDevice(greenhouse?.id, { ...formData, id: currentDevice.id });
            }
        } else {
            // Create New Device
            await createDevice(greenhouse?.id, formData);
        }

        // Refresh List After Save
        const updatedDevices = await fetchAllDevices(greenhouse?.id);
        if (updatedDevices) setRecipies(updatedDevices);

        setModalOpen(false);
        setEditingDeviceId('');
    };

    // Handle Device Deletion
    const handleDelete = async () => {
        if (!selectedDevice) return;
        await deleteDevice(greenhouse?.id, selectedDevice);

        // Refresh List After Delete
        const updatedDevices = await fetchAllDevices(greenhouse?.id);
        if (updatedDevices) setRecipies(updatedDevices);

        setModalOpen(false);
        setDeleteConfirmOpen(false);
    };

    const handleEditClick = (device: Device) => {
        setSelectedDevice(device);
        setEditingDeviceId(device.id);
        setFormData(device);
        setModalOpen(true);
    };

    const handleCancel = () => {
        setEditingDeviceId('');
        setFormData({ id: '', name: '', macId: '', deviceType: '' });
    };

    // Handle Form Changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (
        event: React.SyntheticEvent<HTMLElement>,
        data: DropdownProps
    ) => {
        setFormData({
            ...formData,
            [data.name]: data.value,
        });
    };

    // Otherwise, render the device table
    return (
        <div style={{ marginTop: 10 }}>
            <h2>Device List</h2>
            <Button primary onClick={() => openModal()}>Add New Device</Button>
            {loading ? (
                <p>Loading devices...</p>
            ) : (
                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Mac Address</Table.HeaderCell>
                            <Table.HeaderCell>Device Type</Table.HeaderCell>
                            <Table.HeaderCell>Edit</Table.HeaderCell>
                            <Table.HeaderCell>Delete</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {devices.map((device) => (
                            <Table.Row key={device.id}>
                                <Table.Cell>{device.name}</Table.Cell>
                                <Table.Cell>{device.macId}</Table.Cell>
                                <Table.Cell>{device.deviceType}</Table.Cell>

                                {/* Edit button: either open modal or navigate */}
                                <Table.Cell>
                                    <Button icon onClick={() => handleEditClick(device)}>
                                        <Icon name="edit" />
                                    </Button>
                                    {/* If using a modal directly, do: onClick={() => openEditModal(device)} */}
                                </Table.Cell>

                                {/* Delete button */}
                                <Table.Cell>
                                    <Button
                                        color="red"
                                        icon
                                        onClick={() => {
                                            setSelectedDevice(device);
                                            setDeleteConfirmOpen(true);
                                        }}
                                    >
                                        <Icon name="trash" />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}

            {/* Device Modal */}
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Header>{selectedDevice ? "Edit Device" : "Add New Device"}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input
                            label="Device Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <Form.Input
                            label="Mac Address"
                            name="macId"
                            value={formData.macId}
                            onChange={handleChange}
                            onPaste={(e: any) => handleChange}
                            required
                        />
                        <Form.Select
                            label="Device Type"
                            name="deviceType" // Ensure case matches the key in formData
                            value={formData.deviceType} // Use the correct state key
                            onChange={handleSelectChange}
                            required
                            options={deviceOptions}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setModalOpen(false)}>Cancel</Button>
                    <Button primary onClick={handleSave}>Save</Button>
                </Modal.Actions>
            </Modal>
            {/* Confirmation for delete (from your snippet) */}
            {/* Only opens when deleteConfirmOpen = true, which we set above */}
            {/* (You already have handleDelete logic, so we reuse it) */}
            <Confirm
                open={deleteConfirmOpen}
                onCancel={() => setDeleteConfirmOpen(false)}
                onConfirm={handleDelete}
                content="Are you sure you want to delete this device?"
                confirmButton="Delete"
                cancelButton="Cancel"
                size="mini"
            />
        </div>
    );
};

export default DeviceOverview;