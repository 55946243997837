import React from "react";
import { Card, Icon, SemanticICONS, Button } from "semantic-ui-react";
import "../styles/AirConditionerDisplay.css";
import { FaFan, FaAutoprefixer, FaSnowflake, FaFire, FaTemperatureHigh } from 'react-icons/fa';
import { FaDroplet } from "react-icons/fa6";

export interface AirConditionerProps {
    id: string;
    name: string;
    isOn: boolean;
    temperatureSetPoint: number;
    temperatureMeasured: number;
    outsideTemperature: number;
    mode: 0 | 1 | 2 | 3 | 4;
}

const modeIcons = [
    { label: "Auto", icon: FaAutoprefixer },
    { label: "Dry", icon: FaDroplet },
    { label: "Cool", icon: FaSnowflake },
    { label: "Heat", icon: FaFire },
    { label: "Fan", icon: FaFan },
];

const AirConditioner: React.FC<{ ac: AirConditionerProps; toggleAC: (id: string, value: any, controlType: string) => void }> =
    ({
        ac,
        toggleAC
    }) => {
        return (
            <Card className="modern-ac-card">
                <Card.Content>
                    <div className="ac-header">
                        <h3>{ac.name}</h3>
                    </div>
                    <div className="ac-controls">
                        {modeIcons.map((icon, index) => (
                            <div onClick={() => toggleAC(ac.id, modeIcons.findIndex(obj => obj.label === icon.label), "AC_Mode")}
                                key={index}
                                className={`mode-icon-container ${ac.mode === index ? "active" : ""}`}
                            >
                                <icon.icon className={icon.label === modeIcons[ac.mode].label ? "mode-icon active" : "mode-icon"} />
                                <p>{icon.label}</p>
                            </div>
                        ))}
                    </div>
                    <div className="ac-temperature-display">
                        <span className="temperature-bubble" style={{ fontSize: "0.9em" }}>Set To</span>
                        <span className="temperature-bubble" style={{ fontSize: "2.0em", fontWeight: "bold" }}>{ac.temperatureSetPoint}</span>
                        <div>
                            <span className="temperature-bubble" style={{ fontSize: "1.1em" }}>
                                <FaTemperatureHigh />
                            </span>
                            <span className="temperature-bubble" style={{ fontSize: "1.0em" }}>{ac.temperatureMeasured}</span>
                        </div>
                    </div>
                    <Button circular icon="power" size="big" className={`power-button ${ac.isOn ? 'on' : 'off'}`} onClick={() => toggleAC(ac.id, !ac.isOn, "OnOff")} />
                </Card.Content>
            </Card>
        );
    };

export default AirConditioner;
